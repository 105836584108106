import React from 'react';
import { PasswordForgetForm } from '../components/PasswordForget/Form';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography, Card, CardContent } from '@material-ui/core';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: theme.remSpacing(12),
    textAlign: 'center'
  },
  card: {
    width: 360
  },
  title: {
    color: theme.colors.oxfordBlue06
  }
}));

const PasswordForgetPage = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardContent>
          <Typography variant="h1" className={styles.title} gutterBottom>
            {Localization.getInst().localizedString(LocalizationKey.resetYourPasswordTitle)}
          </Typography>
          <PasswordForgetForm />
        </CardContent>
      </Card>
    </div>
  );
};

export default () => <PasswordForgetPage />;
