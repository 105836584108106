import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography, Button, TextField } from '@material-ui/core';
import { withFirebase } from '../Firebase/withFirebase';
import { FirebaseClient } from '../Firebase/Client';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { Localization } from '../../localization/Localization';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  input: {
    margin: theme.spacing(1),
    width: 250
  },
  actionButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: '43px',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    textTransform: 'none'
  },
  actionButtonDisabled: {
    color: theme.colors.oxfordBlue02
  },
  actionButtonLabel: {
    color: theme.colors.black,
    textDecoration: 'underline'
  },
  actionButtonLabelDisabled: {
    color: theme.colors.oxfordBlue02
  }
}));

interface Props {
  firebase: FirebaseClient;
}

const INITIAL_STATE = {
  email: '',
  error: null
};

const PasswordForgetFormBase = (props: Props) => {
  const [email, setEmail] = useState<string>(INITIAL_STATE.email);
  const [error, setError] = useState<Error | null>(INITIAL_STATE.error);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    props.firebase
      .doPasswordReset(email)
      .then(() => {
        setEmail(INITIAL_STATE.email);
        setError(INITIAL_STATE.error);
      })
      .catch((error) => {
        setError(error);
      });

    event.preventDefault();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.currentTarget.name == 'email') {
      setEmail(event.currentTarget.value);
    }
  };

  const isInvalid = email === '';

  const styles = useStyles();

  const actionButtonClasses = [styles.actionButton];
  if (isInvalid) {
    actionButtonClasses.push(styles.actionButtonDisabled);
  }

  const actionButtonLabelClasses = [styles.actionButtonLabel];
  if (isInvalid) {
    actionButtonLabelClasses.push(styles.actionButtonLabelDisabled);
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        <TextField
          className={styles.input}
          value={email}
          label="e-mail"
          name="email"
          placeholder="e-mail"
          onChange={onChange}
        />
      </div>

      <Button className={actionButtonClasses.join(' ')} disabled={isInvalid} type="submit">
        <Typography variant="h3" className={actionButtonLabelClasses.join(' ')}>
          {Localization.getInst().localizedString(LocalizationKey.sendResetPasswordEmail)}
        </Typography>
      </Button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

export const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
